import clsx from "clsx";
import { BaseHTMLAttributes, useContext, useState } from "react";
import { CategoriesContext } from "@/components/Catalog/Categories/context";
import { Button } from "@/ui/Button/Button";
import { IN_VIEW_START_LIMIT } from "@/utils/categories";
import { cssIsExpanded, cssWithExpanded, StyledCategoryContainer, StyledCategoryFooter } from "./StyledCategory";
export const CategoryContainerEmpty = ({
  children,
  inViewStartParam,
  ...props
}: BaseHTMLAttributes<HTMLDivElement> & {
  inViewStartParam: boolean;
}) => {
  return <StyledCategoryContainer data-in-view-start={inViewStartParam} {...props}>
      {children}
    </StyledCategoryContainer>;
};
export const CategoryContainerExpanded = ({
  children,
  inViewStartParam,
  isExandedMode,
  ...props
}: BaseHTMLAttributes<HTMLDivElement> & {
  inViewStartParam: boolean;
  isExandedMode?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const expandToggleHandle = () => {
    setExpanded(prev => !prev);
  };
  return <StyledCategoryContainer className={clsx(cssWithExpanded, expanded && cssIsExpanded)} data-in-view-start={inViewStartParam} {...props}>
      {children}

      {isExandedMode && <StyledCategoryFooter>
          <Button size={"small"} icon={!expanded ? "AngleBottom" : "AngleTop"} variant={"translucent"} onClick={expandToggleHandle}>
            {!expanded ? "Еще" : "Скрыть"}
          </Button>
        </StyledCategoryFooter>}
    </StyledCategoryContainer>;
};
export const CategoryContainer = ({
  children,
  isParent,
  withExpanded = false,
  categoryId,
  globalParent
}: BaseHTMLAttributes<HTMLDivElement> & {
  isParent: boolean;
  withExpanded?: boolean;
  globalParent?: number;
  categoryId?: number;
}) => {
  const categoriesContext = useContext(CategoriesContext);
  const inViewStart = !!globalParent ? categoriesContext?.inViewTree?.get(globalParent)?.items ?? [] : [];
  const isViewEmpty = inViewStart.length === 0;
  const isExandedMode = !!globalParent ? !!categoriesContext?.inViewTree?.get(globalParent)?.isMore : false;
  const inViewStartParam = isParent || isViewEmpty ? true : !!categoryId ? inViewStart.includes(categoryId) : false;
  if (!withExpanded || !isParent) {
    return <CategoryContainerEmpty inViewStartParam={inViewStartParam} data-global-parent={globalParent}>
        {children}
      </CategoryContainerEmpty>;
  }
  return <CategoryContainerExpanded isExandedMode={isExandedMode} inViewStartParam={inViewStartParam} data-global-parent={globalParent}>
      {children}
    </CategoryContainerExpanded>;
};