import React, { FC, HTMLAttributes, ReactNode } from "react"
import { COMPARE_IMAGES } from "@/components/Catalog/Categories/Categories"
import { CategoryContainer } from "@/components/Catalog/Categories/Category/CategoryContainer"
import { ICategoryTreeItem } from "@/types"
import { EntityImage } from "@/ui/EntityImage/EntityImage"
import { isParentCategory } from "@/utils/categories/helpers"
import { StyledCategory, StyledCategoryImage } from "./StyledCategory"
import { SubCategory } from "./SubCategory"
import { Title } from "./Title"

export type CategoryPropsType = {
  category: ICategoryTreeItem
  isShowImage?: boolean
  childrenAfterParent?: ReactNode
  withExpanded?: boolean
  globalParent?: number
} & HTMLAttributes<HTMLElement>

export const Category: FC<CategoryPropsType> = ({
  category,
  isShowImage = false,
  childrenAfterParent,
  withExpanded,
  globalParent,
}) => {
  const isShowCategory = (category?.product_qty || 0) > 0

  if (!isShowCategory) {
    return null
  }

  const categoriesChilds = Object.entries(category.children).map(
    ([, child]) => child,
  )

  const isParent = isParentCategory({
    parent: category.parent || [],
  })

  const globalParentValue = isParent ? category.id : globalParent

  return (
    <CategoryContainer
      withExpanded={withExpanded}
      isParent={isParent}
      globalParent={globalParentValue}
      categoryId={category.id}
    >
      <StyledCategory
        data-id={category.uuid}
        data-weight={category.weight || 0}
      >
        {(isParent || isShowImage) && (
          <>
            <StyledCategoryImage>
              <EntityImage
                imagePath={
                  !!category.image
                    ? category.image
                    : COMPARE_IMAGES[category.uuid || ""] || undefined
                }
                imageAlt={category.name || ""}
                width={80}
                height={80}
                layout={"intrinsic"}
                objectFit={"cover"}
              />
            </StyledCategoryImage>
          </>
        )}

        <Title
          category={category}
          parent={category?.parent}
          path={category.alias || ""}
        />

        {childrenAfterParent}

        <SubCategory
          categories={categoriesChilds}
          globalParent={globalParentValue}
          withExpanded={withExpanded}
        />
      </StyledCategory>
    </CategoryContainer>
  )
}

Category.displayName = "Category"
