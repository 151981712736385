import React, { forwardRef, memo, useMemo } from "react"
import { CategoriesContext } from "@/components/Catalog/Categories/context"
import {
  CategoriesPropsType,
  InViewTreeType,
} from "@/components/Catalog/Categories/types"
import {
  fillViewTree,
  IN_VIEW_START_LIMIT,
  isParentCategory,
} from "@/utils/categories"
import { Category } from "./Category/Category"
import { CategoriesInner, createCategoriesComponent } from "./StyledCategories"

export const COMPARE_IMAGES = {
  "0e5e9ca0-345c-11e2-a1fb-742f68689961":
    "/images/categories/8a870d6f4c68a454c5d726437b0fd0f6.jpg",
  "64bb00d1-e257-11e7-be89-6c3be5236808":
    "/images/categories/22c60eb64c4eb54177d7cafd53714d7d.jpg",
  "fc802758-8cea-11e9-df8a-ac1f6b855a52":
    "/images/categories/c34c666f56d61f684f809d77f03a4833.jpg",
  "453db070-230a-11e2-871e-742f68689961":
    "/images/categories/0b309598837c095d8222b5c00189e2fe.jpg",
  "0e5e9cdb-345c-11e2-a1fb-742f68689961":
    "/images/categories/46e7989ce960115ae6b2bf5705972e1b.jpg",
  "5dfe2d6b-2288-11e2-9e31-742f68689961":
    "/images/categories/d313b09a8c4b7e8895428bfaa8db6031.jpg",
  "37832fef-1dd8-11e2-bf75-742f68689961":
    "/images/categories/e5e6bf139718d0c154831c3f246a40ee.jpg",
  "1fe15a20-620b-11e2-b522-742f68689961":
    "/images/categories/70f551d8c4d78808abed2bbe469bed0b.jpg",
  "a2e96e34-8cf4-11e9-df8a-ac1f6b855a52":
    "/images/categories/97e04be1b43c0d04363615567638b893.jpg",
  "f44fbc43-21c6-11e2-b10f-38607704c280":
    "/images/categories/677678b9bec4d406c912a455992fd23d.jpg",
  "0b694f44-4f9a-11e9-9e9a-ac1f6b855a52":
    "/images/categories/95dd30fd4566659a90baa1b933f13d13.jpg",
  "453db073-230a-11e2-871e-742f68689961":
    "/images/categories/d387dd945fa30c848654f3ddb6f55d3c.jpg",
  "4319fbe8-5649-11e2-b0e7-38607704c280":
    "/images/categories/fda94458d389575a68a9748973c95e53.jpg",
  "fca0b28d-f4f7-11e7-be8b-6c3be5236808":
    "/images/categories/02efe185cecd8513a5a84d82238cf506.jpg",
  "4b51ac63-6c4d-11e3-891a-38607704c280":
    "/images/categories/1c87408d46d08464a9d16106c938cd85.jpeg",
  "b6c930ef-c92a-11e3-9291-742f68689961":
    "/images/categories/cbef0c6154d645888f9b6efde8e8578b.jpg",
  "f03776b0-355c-11eb-f49d-ac1f6b855a52":
    "/images/categories/8f84f9d311de24313c9fabd1980bb5a8.jpg",
  "d1caaa8e-99e0-11e3-b23a-742f68689961":
    "/images/categories/4629084d52074f181c39415c4331e72d.jpg",
  "4daf8071-9465-11e2-ac6a-742f68689961":
    "/images/categories/40b2b134c1aa0f0530116f06f8fb309e.jpg",
  "902424fe-6d36-11ec-c597-ac1f6b855a52": "/images/categories/5.png",
  "cd0bc9a0-18ef-11ea-8492-ac1f6b855a52": "/images/categories/6.png",
  "2d62676e-23c7-11ea-eb9d-ac1f6b855a52": "/images/categories/7.png",
}

const Categories = memo(
  forwardRef<HTMLDivElement, CategoriesPropsType>(
    ({ categories, variant = "popup", isShowImage }, ref) => {
      const CategoriesContainerComponent = createCategoriesComponent(variant)

      const inViewTree = useMemo(() => {
        const tree: InViewTreeType = new Map()

        for (const category of categories) {
          const categoryId = category.id

          if (!categoryId) {
            continue
          }

          // Если это родительская категория, заносим ее как ключ
          if (
            isParentCategory({
              parent: category.parent ?? [],
            })
          ) {
            tree.set(categoryId, {
              items: [],
              isMore: false,
            })
          }

          fillViewTree(category.children ?? {}, (id: number) => {
            const parentValue = tree.get(categoryId)?.items ?? []

            if (parentValue.length >= IN_VIEW_START_LIMIT) {
              tree.set(categoryId, {
                items: parentValue,
                isMore: true,
              })
              return false
            }

            tree.set(categoryId, {
              items: [...parentValue, id],
              isMore: false,
            })
            return true
          })
        }

        return tree
      }, [categories])

      return (
        <CategoriesContext.Provider
          value={{
            inViewTree,
          }}
        >
          <CategoriesContainerComponent ref={ref}>
            <CategoriesInner>
              {categories.map((category, index) => (
                <Category
                  category={category}
                  key={index}
                  isShowImage={isShowImage}
                  withExpanded
                />
              ))}
            </CategoriesInner>
          </CategoriesContainerComponent>
        </CategoriesContext.Provider>
      )
    },
  ),
)

Categories.displayName = "Categories"

export default Categories
